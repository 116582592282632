import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { Chatbot, InputType, PublicTypebot } from "@typebot-io/engine";
import { ChatbotPage, ChatbotPageProps } from "../components/ChatbotPage";
import * as Sentry from "@sentry/nextjs";
import {
  findChatbotById,
  findChatbotByCustomDomain,
} from "../services/server/publicTypebot";
const axios = require("axios").default;

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  let chatbot: Chatbot | PublicTypebot | undefined;
  const pathname = context.resolvedUrl.split("?")[0];
  const isIE = /MSIE|Trident/.test(context.req.headers["user-agent"] ?? "");
  try {
    if (!context.req.headers.host) return { props: {} };
    chatbot = await getChatbotFromUrl(
      context.req.headers.host,
      pathname,
      context.query
    );
    if (!chatbot) return { props: {} };
    return {
      props: {
        typebot: JSON.parse(
          JSON.stringify({
            ...chatbot,
            createdDate: chatbot?.createdDate?.toString(),
            modifiedDate: chatbot?.modifiedDate?.toString(),
          })
        ),
        url: `https://${context.req.headers.host}${pathname}`,
        embedHostname: context.query.hn ?? null,
        isIE,
      },
    };
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }
  return {
    props: {
      url: `https://${context.req.headers.host}${pathname}`,
      isIE,
    },
  };
};

const getChatbotFromUrl = async (
  hostname: string,
  pathname: string,
  query: any
): Promise<Chatbot | PublicTypebot | undefined> => {
  const chatbot = await (hostname === "bot.typebot.io" ||
  hostname.includes("localhost:3001") ||
  hostname.includes("10.0.2.2:3000")
    ? findChatbotById(pathname.slice(1), query)
    : findChatbotByCustomDomain(hostname.split(":")[0] + pathname, query));
  if (!chatbot) return;
  return chatbot;
};

const App = ({ typebot, url, embedHostname, isIE }: ChatbotPageProps) => (
  <ChatbotPage
    typebot={typebot}
    url={url}
    embedHostname={embedHostname}
    isIE={isIE}
  />
);
export default App;
